exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-how-we-do-it-tsx": () => import("./../../../src/pages/how-we-do-it.tsx" /* webpackChunkName: "component---src-pages-how-we-do-it-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-work-tsx": () => import("./../../../src/pages/our-work.tsx" /* webpackChunkName: "component---src-pages-our-work-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-r-and-d-index-tsx": () => import("./../../../src/pages/r-and-d/index.tsx" /* webpackChunkName: "component---src-pages-r-and-d-index-tsx" */),
  "component---src-pages-who-we-are-index-tsx": () => import("./../../../src/pages/who-we-are/index.tsx" /* webpackChunkName: "component---src-pages-who-we-are-index-tsx" */),
  "component---src-templates-article-embed-tsx": () => import("./../../../src/templates/Article/Embed.tsx" /* webpackChunkName: "component---src-templates-article-embed-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/Article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */)
}

